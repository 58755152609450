import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout, SEO } from '../components'

const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />

      <section className="container pt-5 px-5 pb-0 text-center">
        <h1 className="mt-md-5 mb-4">Small team. Big hearts.</h1>
        <p className="lead mb-5">
          Our focus is always on finding the best people to work with.
        </p>
      </section>
      <section className="container px-md-5 pb-5">
        <Img
          className="img-fluid"
          fluid={data.team.childImageSharp.fluid}
          alt="Time Lapse team"
        />
      </section>

      {/* <section className="container vision my-md-5">
        <div className="row">
          <div className="col-12 col-md-6 pt-5 pt-md-0 px-md-0 mr-lg-5">
            <span className="corner-triangle"></span>
            <img className="img-fluid" src="https://source.unsplash.com/800x450/?timelapse" alt="Timelapse" />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-offset-lg-2 my-0 my-md-auto mr-auto p-5">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Our vision</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title">Work Smart. Not Hard.</h3>
                <p className="lead">We aim to remove all barriers between you and just getting the most out of your project.</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="container pt-5">
        <div className="card text-center border-0 bg-transparent">
          <div className="card-header px-0">
            <small className="text-uppercase">Who we are</small>
          </div>
          <div className="card-body pt-0 px-0 mb-4">
            <h3 className="card-title">The team</h3>
            <p className="lead">Our team allows us to maintain operational efficiency while working toward a shared goal.</p>
          </div>
        </div>
      </section>
      
      <section className="container pb-5">
        <div className="row pb-5">
          <div className="col-md-7 col-md-offset-1 order-2 mt-2 my-md-auto">
            <div className="card border-0 bg-transparent">
              <div className="card-header p-0">
                <span className="text-uppercase">Director + Producer</span>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title h4 text-uppercase mb-2">Mohd Izwan Mohd Rizal Khong</h3>
                <p>izwan.khong@timelapse.com.my</p>
                <p>Since founding TIMELAPSE in 2013, Mohd Izwan has propelled the company to greater heights as the first and leading time-lapse image capture company in Malaysia.</p>
                <p>Both leader and creative professional, Mohd Izwan dedicates his efforts to translating client and project requirements to the production team, as well as researching the technical aspects of a project to achieve optimum results.</p>
                <p className="mb-0">Working with architects, engineers and project managers, he has established TIMELAPSE as a reliable partner in the construction industry and on large scale projects on land, underground and offshore, with notable clients such as PETRONAS, IKEA, IJM, HSBC, TRX and more.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 order-1 mr-md-4 my-auto pl-md-0 w-50 w-md-100">
            <Img
              className="img-fluid"
              fluid={data.director.childImageSharp.fluid}
              alt="Director + Producer"
            />
          </div>
        </div>

        <div className="row pb-5">
          <div className="col-md-7 col-md-offset-1 order-2 mt-2 my-md-auto">
            <div className="card border-0 bg-transparent">
              <div className="card-header p-0">
                <span className="text-uppercase">Finance Director</span>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title h4 text-uppercase mb-2">Ahmad Huzaini bin Mahmud</h3>
                <p>huzaini.mahmud@timelapse.com.my</p>
                <p>With a solid financial background and an MBA in risk management, Ahmad Huzaini brings his considerable wealth of corporate expertise and people network to the TIMELAPSE brand.</p>
                <p className="mb-0">Overseeing the company's business development and administration, Ahmad's forte is a broad spectrum which includes financial auditing, analysis, projections, tenders and sales. He maintains a strong presence in the luxury automobile industry as part of the company's overall brand building.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 order-1 mr-md-4 my-auto pl-md-0 w-50 w-md-100">
            <Img
              className="img-fluid"
              fluid={data.finance.childImageSharp.fluid}
              alt="Director + Producer"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 col-md-offset-1 order-2 mt-2 my-md-auto">
            <div className="card border-0 bg-transparent">
              <div className="card-header p-0">
                <span className="text-uppercase">Creative Director</span>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title h4 text-uppercase mb-2">Mohd Shah Rizal bin Husain</h3>
                <p>shah.rizal@timelapse.com.my</p>
                <p>Shah Rizal's experienced eye lies behind many of TIMELAPSE's signature production. A professional camera operator, drone pilot and video editor, he is passionate in editing and motion graphics, and lends his creative talents particularly in developing time-lapse content for site progress video reports and project documentary.</p>
                <p className="mb-0">Capable of shooting in any environment, he represents TIMELAPSE's commitment to exceptional visual standards and sets the bar in construction image capture.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 order-1 mr-md-4 my-auto pl-md-0 w-50 w-md-100">
            <Img
              className="img-fluid"
              fluid={data.creative.childImageSharp.fluid}
              alt="Director + Producer"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bg-dark text-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <i className="material-icons md-36 mb-2">chat</i>
              <h3 className="mb-1">
                Interested in joining our team?
              </h3>
              <p className="lead mb-5 mb-md-0">
              Hit us up and we'll get in touch with you.
              </p>
            </div>
            <div className="col-12 col-md-auto">
              <a href="/contact/" className="btn btn-lg btn-primary">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    team: file(relativePath: {eq: "about-team.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    director: file(relativePath: {eq: "about-izwan.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 600, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    finance: file(relativePath: {eq: "about-huzaini.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 600, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    creative: file(relativePath: {eq: "about-shahrizal.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 600, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`